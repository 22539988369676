<template>
  <el-dialog :visible.sync="showFileSelect" width="900px" class="image-select dialog-vertical" append-to-body>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="媒体库" name="first">
        <div class="select-top">
          <div style="flex: 1">
            <el-button type="primary" @click="chooseFile">选择文件</el-button>
          </div>
          <!-- <div class="select-right">
            <el-select style="width:200px" v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select style="width:200px" v-model="value" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
            </el-select>
            <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search"
              v-model="fileName"></el-input>
          </div> -->
        </div>
        <div class="select-center">
          <el-checkbox-group class="img-list" v-model="checkList" v-loading="mediaLoading">
            <div class="img-item" v-for="(imgItem, index) in imgList" :key="imgItem.id"
              @click="handleSelect(imgItem)">
              <el-checkbox :label="imgItem" @change="handleSelect(imgItem)">{{imgItem.title}}</el-checkbox>
              <el-image v-if="imgItem.type === 'image'" class="item-img" :src="imgItem.url" fit="contain"
                scroll-container=".img-list" lazy>
                <div slot="placeholder" class="image-slot">加载中...</div>
              </el-image>
              <el-image v-if="imgItem.type === 'video'" class="item-img" :src="imgItem.thumbnail"
                fit="contain" scroll-container=".img-list" lazy>
              </el-image>
            </div>
          </el-checkbox-group>
          <div class="pagination">
            <el-pagination background layout="prev, pager, next" :page-size="requestData.page_size"
              :total="pageData.total" :current-page="Number(requestData.page)" @current-change="pageChange">
            </el-pagination>
            <el-input class="number-input" type="number" min="1" :max="pageData.last_page" auto-complete="off"
              v-model="requestData.page" @keyup.enter.native="changePage"></el-input>
            <el-button class="page-jump" type="primary" @click="changePage">GO</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane class="phone-upload" label="本地上传" name="second">
      </el-tab-pane>
      <el-tab-pane class="phone-upload" label="手机上传" name="thrid">
        <div class="phone-scan">
          <div class="scan-fream">
            <el-image class="scan-code" :src="scanCodeImg" fit="cover"></el-image>
          </div>
          <div class="scan-tip">扫一扫二维码，快速上传手机图片</div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <upload-dialog v-model="openUploadDialog" :on-complete="updateImage" :accept="acceptType"
      :limitType="limitType" :showMask="false"></upload-dialog>
    <template #footer>
      <el-button @click="closeSelect">取消</el-button>
      <el-button type="primary" @click="confirmSelect">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { MediaBounced } from "../api/media";
import { scanCode } from "../api/upload.js";
import UploadDialog from "./UploadDialog.vue";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentImg: {
      type: String,
      default: "",
    },
    // 上传的文件类型
    upLoadType: {
      type: [String, Array],
      default: "image",
    },
    // 最多可以选多少张图片
    max: {
      type: Number,
      default: 9,
    },
  },
  data() {
    return {
      activeName: "first",
      imgList: [],
      checkList: [],
      checkImg: {},
      limitType: [],
      scanCodeImg: "",
      acceptType: "",
      fileName: "",
      requestData: {
        page: 1,
        page_size: 15,
        type: "image",
        order_by: "type",
      },
      pageData: {},
      mediaLoading: false,
      openUploadDialog: false,
    };
  },
  methods: {
    // 获取图片列表
    getMediaList() {
      this.imgList = [];
      this.mediaLoading = true;
      this.requestData.type = this.upLoadType;
      MediaBounced(this.requestData)
        .then((res) => {
          const { data } = res;
          this.imgList = data.data;
          this.pageData = data;
          this.requestData.page = this.pageData.current_page;
          this.mediaLoading = false;
        })
        .catch((err) => {
          this.mediaLoading = false;
        });
    },
    // 获取手机上传二维码
    getScanCode() {
      scanCode()
        .then((res) => {
          console.log(res.data);
          this.scanCodeImg = res.data;
        })
        .catch((err) => {});
    },
    // 点击改变页数
    pageChange(e) {
      this.requestData.page = e;
      this.getMediaList();
    },
    // 输入改变页数
    changePage() {
      console.log(this.requestData.page);
      if (
        Number(this.requestData.page) > 0 &&
        Number(this.requestData.page) <= Number(this.pageData.last_page)
      ) {
        this.getMediaList();
      } else {
        this.requestData.page = this.pageData.current_page;
      }
    },
    // 选中图片操作
    handleSelect(obj) {
      // if (this.checkList[0] && this.checkList[0] == item.id) {
      //   this.checkList.splice(0, 1);
      //   this.checkImg = "";
      // } else {
      //   this.checkList = [item.id];
      //   this.checkImg = item.url;
      // }
      let index = this.checkList.findIndex((item) => item.id == obj.id);
      let tipWord =
        this.upLoadType === "video"
          ? "个视频"
          : typeof this.upLoadType === "object" && this.upLoadType.length === 2
          ? "个视频或图片"
          : "张图片";
      if (index > -1) {
        this.checkList.splice(index, 1);
      } else {
        if (this.max > 1 && this.checkList.length + 1 > this.max) {
          this.$message.error("最多可选" + this.max + tipWord);
          return;
        }
        if (this.max === 1 && this.checkList.length + 1 > this.max) {
          this.checkList.splice(index, 1);
          this.checkList.push(obj);
          return;
        }
        this.checkList.push(obj);
      }
    },
    handleClick() {},
    // 点击确认
    confirmSelect() {
      if (this.checkList.length === 0) return;
      this.showFileSelect = false;
      this.$emit("handleChoose", this.checkList);
    },
    // 点击取消
    closeSelect() {
      this.showFileSelect = false;
    },
    // 选择本地文件
    chooseFile() {
      if (typeof this.upLoadType === "string") {
        this.limitType = [this.upLoadType];
        this.acceptType = this.upLoadType + "/*";
      } else {
        let typeList = [];
        this.upLoadType.forEach((item) => {
          typeList.push(item + "/*");
        });
        this.limitType = typeList;
        this.acceptType = typeList.join(",");
      }
      this.openUploadDialog = true;
    },
    // 上传成功刷新图片列表
    updateImage() {
      this.getMediaList();
    },
  },
  computed: {
    showFileSelect: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  watch: {
    open(val) {
      this.checkList = [];
      if (val) {
        this.getMediaList();
        this.getScanCode();
      }
    },
  },
  components: {
    UploadDialog,
  },
};
</script>
<style lang="scss">
.image-select {
  > .el-dialog {
    > .el-dialog__header {
      display: none;
    }
    > .el-dialog__body {
      padding: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.el-tabs {
  background-color: #fff;
  ::v-deep.el-tabs__header {
    margin: 0;
  }
  .el-tab-pane {
    width: 860px;
    height: 560px;
    padding: 10px;
    border-left: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    .select-top {
      display: flex;
      justify-content: space-between;
      .select-right {
        display: flex;
        justify-content: space-between;
        flex: 3;
      }
    }
    .select-center {
      width: 100%;
      height: 500px;
      .img-list {
        width: 100%;
        height: 460px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        .img-item {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          width: 32%;
          height: 200px;
          margin-left: 1%;
          margin-top: 15px;
          padding: 12px 10px;
          background-color: #f1f1f1;
          .item-img {
            display: block;
            width: 100%;
            height: 150px;
          }
          .image-slot {
            text-align: center;
            color: $placeholder;
            margin-top: 50px;
            font-size: 14px;
          }
          ::v-deep.el-checkbox {
            .el-checkbox__input {
              vertical-align: unset;
            }
            .el-checkbox__label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 90%;
            }
          }
        }
      }

      .pagination {
        display: flex;
        height: 50px;
        align-items: center;
        .number-input {
          max-width: 60px;
          ::-webkit-inner-spin-button,
          ::-webkit-outer-spin-button {
            appearance: none;
          }
          ::v-deep.el-input__inner {
            height: 28px;
            border: 1px solid #dcdfe6;
            padding: 0 10px;
          }
        }
        .page-jump {
          height: 28px;
          line-height: 0;
          padding: 10px;
          margin-left: 10px;
        }
      }
    }
  }
}

.phone-upload {
  display: flex;
  justify-content: center;
  align-items: center;

  .phone-scan {
    .scan-fream {
      width: 210px;
      height: 210px;
      padding: 10px;
      margin: auto;
      border: 1px solid #d8dce6;

      .scan-code {
        width: 100%;
        height: 100%;
      }
    }

    .scan-tip {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #303133;
    }
  }
}
</style>
