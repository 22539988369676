<template>
  <div class="content" v-loading="loading">
    <MiniProgramViewer class="start-left"
                       :footer-image="startForm.bottom"
                       :footer-text="startForm.copyright">
      <div class="img-swiper" :class="startForm.images.length > 0?'':'empty-image'">
        <slider ref="slider" :options="options" v-if="startForm.images.length > 0">
          <slideritem v-for="(item,index) in startForm.images" :key="item + index">
            <el-image :src="item" fix="cover"></el-image>
          </slideritem>
        </slider>
      </div>
      <el-image class="start-top-title" v-if="startForm.middle" :src="startForm.middle"></el-image>
    </MiniProgramViewer>
    <div class="start-right">
      <el-form label-width="100px" size="medium" :model="startForm" class="small-from" label-position="top">
        <el-form-item label="轮播图片设置">
          <div style="display: flex;align-items: center">
            <el-radio v-model="status" :label="0" @change="handleChange">默认</el-radio>
            <el-radio v-model="status" :label="1" @change="handleChange">自定义</el-radio>
          </div>
        </el-form-item>
        <el-form-item :label="status == 0?'默认':'自定义'" required>
          <draggable v-model="startForm.images" class="img-list" :disabled="false" animation="500"
                     draggable=".img-item">
            <template v-if="startForm.images.length > 0">
              <div class="img-item" v-for="(img, index) in startForm.images" :key="index">
                <options-image :src="startForm.images[index]" :preview-src-list="startForm.images" :hide-delete="!status"
                               @delete="startForm.images.splice(index, 1)"></options-image>
              </div>
            </template>
            <div class="img-item-add" v-if="status && startForm.images.length < 8">
              <add-button text="" @click="handleShowImgSelect(0)"></add-button>
            </div>
          </draggable>
          <div class="img-tips" style="line-height: 12px;margin-top: 0">尺寸推荐 180*180</div>
          <div style="font-size: 12px;color: #3479EF;margin-top: 16px;line-height: 17px;">
            {{status == 0?'拖动可调整图片的展示顺序，默认的轮播图片无法删除':'拖动可调整图片的展示顺序' }}
          </div>
        </el-form-item>
        <el-form-item label="底部图片" required>
          <options-image v-if="!status" height="60px" width="60px" :src="startForm.bottom" hide-delete></options-image>
          <SingleMediaWall v-else v-model="startForm.bottom" ratio="520:60" :width="60" :height="60" :borderRadius="4"
                           add-text="" fit="contain">
          </SingleMediaWall>
          <div class="img-tips" style="line-height: 12px;">尺寸推荐 520*60</div>
          <div style="font-size: 12px;color: #3479EF;margin-top: 16px;line-height: 17px;">
            {{ status == 0?'默认的底部图片无法删除':'' }}
          </div>
        </el-form-item>
      </el-form>
    </div>
    <image-select v-model="show" :max="maxLength" @handleChoose="handleMedia"/>
    <fixed-action-bar>
      <el-button size="medium" type="primary" @click="handleSaveStart">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import SingleMediaWall from "../../common/components/SingleMediaWall";
  import ImageSelect from "../../common/components/ImageSelect";
  import draggable from "vuedraggable";
  import api from "../../../base/utils/request";
  import {slider, slideritem} from "vue-concise-slider";
  import MiniProgramViewer from "../../../base/components/Preview/MiniProgramViewer";
  import AddButton from "../../../base/components/Base/AddButton";
  import OptionsImage from "../../../base/components/Media/OptionsImage";
  import FixedActionBar from "../../../base/layout/FixedActionBar";

  export default {
    name: "admin-start-page",
    data() {
      return {
        loading: true,
        status: 0,
        startForm: {
          middle: "",
          bottom: "",
          images: [],
        },
        show: false,
        startConfig: {},
        options: {
          thresholdDistance: 30,
          loop: true,
          pagination: false,
          itemAnimation: true,
        },
        disabled: false,
        selectIndex: 0,
        maxLength: 8,
      };
    },
    components: {
      FixedActionBar,
      OptionsImage,
      AddButton,
      MiniProgramViewer,
      SingleMediaWall,
      ImageSelect,
      draggable,
      slider,
      slideritem,
    },
    watch: {
      status() {
        this.disabled = !!this.status;
      },
    },
    created() {
      this.getStartConfig();
    },
    methods: {
      handleSaveStart() {
        this.loading = true;
        let dataObj = {};
        if (!this.status) {
          dataObj.config = {
            status: this.status,
            custom: {images: [], middle: ""},
          };
        } else {
          dataObj.config = {custom: this.startForm, status: this.status};
        }
        console.log(dataObj);
        api({
          url: "/admin/cyc/Settings/setCycStartPageConfig",
          method: "post",
          data: dataObj,
        }).then((res) => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      handleChange() {
        if (this.status) {
          this.$nextTick(() => {
            this.startForm = Object.assign({}, {middle: '', bottom: ''}, this.startConfig.custom);
          });
        } else {
          this.$nextTick(() => {
            this.startForm = this.startConfig.system;
          });
        }
      },
      getStartConfig() {
        api({
          url: "/admin/cyc/Settings/getCycStartPageConfig",
          method: "post",
        })
          .then((res) => {
            this.status = res.data.status;
            this.startConfig = res.data;
            this.startConfig.custom.middle = this.startConfig.system.middle;
            this.startConfig.custom.copyright = this.startConfig.system.copyright;
            if (this.status) {
              this.startForm = this.startConfig.custom;
              this.disabled = true;
            } else {
              this.startForm = this.startConfig.system;
              this.disabled = false;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      handleShowImgSelect(i) {
        if (i && this.startForm.images[i]) return;
        if (i) {
          this.maxLength = 1;
        } else {
          this.maxLength = 8 - this.startForm.images.length;
        }
        this.selectIndex = i;
        this.show = true;
      },
      handleMedia(data) {
        if (this.selectIndex) {
          this.startForm.images[this.selectIndex] = data[0].url;
        } else {
          data.forEach((img, index) => {
            this.startForm.images.push(img.url);
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .content {
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
  }

  .start-left {
    .img-swiper {
      width: 90px;
      height: 90px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 172px;
    }

    .empty-image {
      background-color: $image-placeholder;
    }

    .start-top-title {
      width: 288px;
      height: 19px;
      position: absolute;
      top: 296px;
      left: 44px;
    }
  }

  .start-right {
    margin-left: 48px;

    .img-list {
      /*width: 278px;*/
      display: flex;
      flex-wrap: wrap;
    }

    .img-item, .img-item-add {
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
      margin-right: 12px;
    }

    .img-item {
      box-sizing: border-box;
      cursor: move;

      /*&:nth-child(4n) {*/
      /*  margin-right: 0;*/
      /*}*/
    }

    .img-tips {
      font-size: 12px;
      color: #c0c4cc;
      margin-top: 12px;
    }
  }
</style>
