<template>
  <div class="mini-program-viewer">
    <el-image class="mini-program-head" :class="[$route.params.platform]"
              :src="statusBarColor === 'white' ? require('../../assets/images/white-nav.png') : require('../../assets/images/black-nav.png')"></el-image>
    <div class="mini-program-nav-bar flex-between" :class="[$route.params.platform]">
      <!-- 左侧胶囊 -->
      <template v-if="$route.params.platform === 'h5'">
        <i class="el-icon-close" style="font-size: 24px"></i>
        <i class="el-icon-more"></i>
      </template>
      <template v-else>
        <img v-if="isShowLeft" class="default-back-icon" src="../../assets/images/Back1@2x.png" />
        <div v-else></div>
        <img class="template-back-icon" :src="blackCapsule"/>
      </template>
    </div>
    <div class="mini-program-content">
      <div class="mini-program-content-slot">
        <slot></slot>
      </div>
      <div class="mini-program-footer" v-if="isShowFooter">
        <el-image v-if="footerImage" class="mini-program-footer-icon" :src="footerImage" fit="contain">
        </el-image>
        <div v-else class="mini-program-footer-icon-empty"></div>
        <div class="mini-program-footer-text">{{footerText}}</div>
      </div>
      <div class="mini-program-footer" v-else>
        <div class="mini-program-footer-text">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniProgramViewer",
  props: {
    navTitle: {
      type: String,
      default: "",
    },
    footerImage: {
      type: String,
      default: "",
    },
    footerText: {
      type: String,
      default: "",
    },
    isShowFooter: {
      type: Boolean,
      default: true,
    },
    isShowLeft: {
      type: Boolean,
      default: true
    },
    statusBarColor: {
      type: String,
      default: 'black'
    },
  },
  data() {
    return {
      blackCapsule: require("../../assets/images/wechat-handle1@2x.png"),
    }
  },
};
</script>

<style lang="scss" scoped>
.mini-program-viewer {
  width: 375px;
  /*border: 1px solid #dcdfe6;*/
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;

  .mini-program-head {
    width: 375px;
    height: 64px;
    min-height: 64px;
    z-index: 1;

    &.h5 {
      background-color: rgb(234, 234, 234);
    }
  }

  .mini-program-nav-bar {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 44px;
    background-color: white;
    padding: 0 10px;
    z-index: 1;

    &.h5 {
      background-color: rgb(234, 234, 234);
    }

    .default-back-icon {
      width: 20px;
    }

    .template-back-icon {
      width: 78px;
      height: 32px;
    }

    .el-icon-more {
      position: relative;
      right: 6px;
    }
  }

  .mini-program-content {
    height: 603px;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .mini-program-content-slot {
      flex: 1;
    }
  }

  .mini-program-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 26px;

    .mini-program-footer-icon,
    .mini-program-footer-icon-empty {
      width: 260px;
      height: 30px;
      margin-bottom: 4px;
    }

    .mini-program-footer-icon-empty {
      background-color: $image-placeholder;
      // border: 1px solid #dcdfe6;
    }

    .mini-program-footer-text {
      width: 100%;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      text-align: center;
    }
  }
}
</style>
