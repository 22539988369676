import { render, staticRenderFns } from "./admin-start-page.vue?vue&type=template&id=5aebfe32&scoped=true&"
import script from "./admin-start-page.vue?vue&type=script&lang=js&"
export * from "./admin-start-page.vue?vue&type=script&lang=js&"
import style0 from "./admin-start-page.vue?vue&type=style&index=0&id=5aebfe32&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aebfe32",
  null
  
)

export default component.exports